import request from "@/api/service";

const api = {
   marketingSalesSituation: '/order/mallMarketing/marketingSalesSituation',
   select: '/hotel/getList',
   marketingSalesChart: '/order/mallMarketing/marketingSalesChart'
}

export function getMarketingSalesSituation(params) {
   return request({
      url: api.marketingSalesSituation,
      method: 'POST',
      data: params
   })
}

export function selectHotel(params) {
   return request({
      url: api.select,
      method: 'POST',
      data: params
   })
}

export function getMarketingSalesChart(params) {
   return request({
      url: api.marketingSalesChart,
      method: 'POST',
      data: params
   })
}
