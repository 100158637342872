<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <div class="content-box">
      <el-row class="search-box">
        <el-row class="search-row">
          <el-row class="search-item">
            <el-button-group>
              <template>
                <el-button @click="changeTab('DAY')" :type="dateType === 'DAY' ? 'primary' : ''">本日</el-button>
                <el-button @click="changeTab('YESTERDAY')" :type="dateType === 'YESTERDAY' ? 'primary' : ''">昨日</el-button>
                <el-button @click="changeTab('WEEK')" :type="dateType === 'WEEK' ? 'primary' : ''">本周</el-button>
                <el-button @click="changeTab('MONTH')" :type="dateType === 'MONTH' ? 'primary' : ''">本月</el-button>
                <el-button @click="changeTab('DATE')" :type="dateType === 'DATE' ? 'primary' : ''">自定义</el-button>
              </template>
            </el-button-group>
          </el-row>
          <el-row class="search-item" v-if="dateType === 'DATE'">
            <label><span style="color: red">*</span>营业时间：</label>
            <date-packer :datePacker="dateRange" @setDatePacker="getDatePacker" format="yyyy-MM-dd"></date-packer>
          </el-row>
          <el-button class="bg-gradient" type="primary" @click="handleQuery(true)">搜索</el-button>
          <el-button type="primary" plain @click="handleQuery(false)">重置</el-button>
        </el-row>
      </el-row>
      <el-row class="card-box" type="flex" justify="space-between">
        <div><p>平台销售额<span>{{ platformMarketingData.totalAmount }}</span></p></div>
        <div><p>平台核销金额<span>{{ platformMarketingData.writeOffAmount }}</span></p></div>
        <div><p>平台推广佣金<span>{{ platformMarketingData.awardAmount }}</span></p></div>
        <div><p>平台实际收入<span>{{ platformMarketingData.actualIncome }}</span></p></div>
      </el-row>
      <el-row class="search-box m-top-50">
        <el-row class="search-row">
          <el-row class="search-item">
            <label>选择酒店：</label>
            <el-select v-model="hotelId" @change="changeHotel">
              <el-option v-for="item in hotelOpt" :key="item.id" :value="item.id" :label="item.hotelName"></el-option>
            </el-select>
          </el-row>
        </el-row>
      </el-row>
      <el-row class="card-box" type="flex" justify="space-between">
        <div><p>销售额<span>{{ hotelMarketingData.totalAmount }}</span></p></div>
        <div><p>核销金额<span>{{ hotelMarketingData.writeOffAmount }}</span></p></div>
        <div><p>推广佣金<span>{{ hotelMarketingData.awardAmount }}</span></p></div>
        <div><p>实际收入<span>{{ hotelMarketingData.actualIncome }}</span></p></div>
      </el-row>
      <el-row id="chart"></el-row>
    </div>
  </div>
</template>
<script>
import { getMarketingSalesSituation, selectHotel,
  getMarketingSalesChart } from '@/api/shop/statement/salesOverview';
import { dateFactory } from '@/common/js/common';
import { mapState } from 'vuex';
export default {
  data () {
    return {
      crumbs: new Map([
          ['特惠商城'], ['对账报表'], ['销售概况']
      ]),
      dateRange: [],
      hotelOpt: [],
      hotelId: '',
      dateType: 'DAY',
      platformMarketingData: {
        actualIncome: 0,
        awardAmount: 0,
        totalAmount: 0,
        writeOffAmount: 0
      },
      hotelMarketingData: {}
    }
  },
  computed: {
    ...mapState(['hotelInfo'])
  },
  mounted() {
    this.hotelMarketingData = { ...this.platformMarketingData }
    this.accountType = JSON.parse(sessionStorage.getItem('userInfo') || '{}').accountType
    if (this.accountType === 'PLATFORM') {
      this.getHotelOpt()
      this.getMarketingSalesSituation('platform', this.hotelInfo.id)
    } else {
      this.getSalesAmount(this.hotelInfo.id)
      this.getMarketingSalesSituation('hotel', this.hotelInfo.id)
    }
  },
  methods: {
    // 改变tab栏
    changeTab(dateType) {
      this.dateType = dateType
    },
    // 获取订单时间
    getDatePacker(val) {
      this.dateRange = val
    },
    // 查询订单
    handleQuery(bool){
      if (bool) {
        if (this.dateType === 'DATE' && this.dateRange.length === 0) {
          this.$message({ message: '请选择时间范围！', type: 'warning' })
          return
        }
        if (this.accountType === 'PLATFORM') {
          this.getMarketingSalesSituation('platform')
          this.getMarketingSalesSituation('hotel', this.hotelId)
          this.getSalesAmount(this.hotelId)
        }else {
          this.getSalesAmount(this.hotelInfo.id)
          this.getMarketingSalesSituation('hotel', this.hotelInfo.id)
        }
      } else {
        this.dateType = 'DAY'
        this.dateRange = []
      }
    },
    // 获取市场营销数据
    getMarketingSalesSituation(type, hotelId) {
      const params = { hotelId };
      if (this.dateType === 'DAY') {
        params.beginDateStr = dateFactory.dateFormat(false)
        params.endDateStr = dateFactory.dateFormat(false)
      }
      if (this.dateType === 'YESTERDAY') {
        params.beginDateStr = dateFactory.getDistanceToday(-1, false)
        params.endDateStr = dateFactory.getDistanceToday(-1, false)
      }
      if (this.dateType === 'WEEK') {
        params.beginDateStr = dateFactory.getWeekDate('the', 'start', false)
        params.endDateStr = dateFactory.getWeekDate('the', 'end', false)
      }
      if (this.dateType === 'MONTH') {
        params.beginDateStr = dateFactory.getMonthDate('start', false)
        params.endDateStr = dateFactory.getMonthDate('end', false)
      }
      if (this.dateType === 'DATE') {
        params.beginDateStr = this.dateRange[0]
        params.endDateStr = this.dateRange[1]
      }
      if (type === 'platform' && this.hotelInfo.hotelName === '达易住特惠') delete params.hotelId
      getMarketingSalesSituation(params).then(({ success, records }) => {
        if (success) {
          if (records) {
            for (const k in this[type + 'MarketingData']) {
              k in records && (this[type + 'MarketingData'][k] = records[k])
            }
          } else {
            for (const k in this[type + 'MarketingData']) {
              this[type + 'MarketingData'][k] = 0
            }
          }
        }
      })
    },
    // 获取酒店下拉
    getHotelOpt() {
      selectHotel({}).then(({ success, records }) => {
        if (success) {
          this.hotelOpt = records.map(item => ({ id: item.id, hotelName: item.hotelName }))
          this.hotelId = this.hotelOpt[0].id
          this.getMarketingSalesSituation('hotel', this.hotelId)
          this.getSalesAmount(this.hotelId)
        }
      })
    },
    // 改变当前酒店
    changeHotel() {
      this.getMarketingSalesSituation('hotel', this.hotelId)
      this.getSalesAmount(this.hotelId)
    },
    // 获取销售金额
    getSalesAmount(hotelId) {
      const option = {
        legend: {
          data: ['销售额', '核销金额', '推广佣金', '实际收入']
        },
        grid: {
          left: '5%',
          right: '5%',
          top: '14%',
          bottom: '10%',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            rotate: 45
          },
          data: [],
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '销售额',
          type: 'line',
          stack: '总量',
          data: [],
          smooth: true,
          itemStyle: {
            normal: {
              color: '#006FFF', //改变折线点的颜色
              lineStyle: {
                color: '#006FFF' //改变折线颜色
              }
            }
          },
          }, {
          name: '核销金额',
          type: 'line',
          stack: '总量',
          data: [],
          smooth: true,
          itemStyle: {
            normal: {
              color: 'rgb(78, 211, 181)', //改变折线点的颜色
              lineStyle: {
                color: 'rgb(78, 211, 181)' //改变折线颜色
              }
            }
          },
        }, {
          name: '推广佣金',
          type: 'line',
          stack: '总量',
          data: [],
          smooth: true,
          itemStyle: {
            normal: {
              color: 'rgb(245, 108, 108)', //改变折线点的颜色
              lineStyle: {
                color: 'rgb(245, 108, 108)' //改变折线颜色
              }
            }
          },
        },  {
          name: '实际收入',
          type: 'line',
          stack: '总量',
          data: [],
          smooth: true,
          itemStyle: {
            normal: {
              color: 'rgb(255, 161, 50)', //改变折线点的颜色
              lineStyle: {
                color: 'rgb(255, 161, 50)' //改变折线颜色
              }
            }
          },
        }
        ],
      }
      const params = { hotelId };
      if (this.dateType !== 'DATE' && this.dateType !== 'YESTERDAY') params.dateType = this.dateType
      if (this.dateType === 'YESTERDAY') params.yesterday = 1
      if (this.dateType === 'DATE') {
        params.beginDateStr = this.dateRange[0]
        params.endDateStr = this.dateRange[1]
      }
      getMarketingSalesChart(params).then(({ success, records }) => {
        if (success) {
          if (this.dateType === 'DAY' || this.dateType === 'YESTERDAY') records.reverse()
          records.forEach(item => {
            if (this.dateType === 'DAY' || this.dateType === 'YESTERDAY') {
              item.date = item.date.substring(item.date.length - 2)
            }
            option.xAxis.data.push(item.date)
            option.series[0].data.push(item.totalAmount || 0)
            option.series[1].data.push(item.writeOffAmount || 0)
            option.series[2].data.push(item.awardAmount || 0)
            option.series[3].data.push(item.actualIncome || 0)
          })
          this.$echarts.init(document.getElementById('chart')).setOption(option);
        }
      })
    }
  },
  watch: {
    hotelInfo(newVal, oldVal) {
      oldVal && newVal.id !== oldVal.id && this.getMarketingSalesSituation('platform', this.hotelInfo.id)
    }
  }
}
</script>
<style scoped lang="scss">
.cont {
  .content-box {
    .card-box {
      $gradient-blue: linear-gradient(90deg, #006FFF, #189AFF);
      $gradient-green: linear-gradient(90deg, rgb(78, 211, 181), rgb(41, 231, 188));
      $gradient-red: linear-gradient(90deg, rgb(245, 108, 108), rgb(244, 127, 64));
      $gradient-yellow: linear-gradient(90deg, rgb(255, 161, 50), rgb(255, 209, 50));
      $list-bg: $gradient-blue, $gradient-green, $gradient-red, $gradient-yellow;
      div {
        width: 22%; line-height: 120px; background-repeat: no-repeat;
        text-align: left; background-position: 0 0; background-size: 100% 100%;
        padding: 0 40px; box-sizing: border-box; color: #FFFFFF; border-radius: 6px;
        @each $c in $list-bg {
          $i: index($list-bg, $c);
          &:nth-child(#{$i}) { background: nth($list-bg, $i); }
        }
        p {
          font-size: 18px; font-weight: 400;
          span { margin-left: 10px; font-size: 28px; font-weight: 700 }
        }
      }
    }
    #chart {
      width: 100%; height: 400px; margin-top: 50px;
    }
  }
}
</style>
